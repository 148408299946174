/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.list-pagination {
  background: transparent;
}

.list-pagination nav > span, .list-pagination nav > a {
  display: inline-block;
  margin: 2px 1px 2px 0;
  padding: 4px 8px;
  border: 1px solid #d2d2d2;
  font-size: 13px;
  font-weight: bold;
}

.list-pagination nav > span:hover, .list-pagination nav > a:hover {
  color: #89C6CC;
  background: #f6f6f6;
  text-decoration: none;
  border: 1px solid #15909c;
}

.list-pagination nav > span:focus, .list-pagination nav > a:focus {
  text-decoration: none;
}

.list-pagination nav > span.active {
  background: #f6f6f6;
  color: #89C6CC;
  border: 1px solid #d2d2d2;
}

.list-pagination nav > span.inactive {
  color: #aaa;
}

.list-pagination nav > span.inactive:hover {
  background: none;
  border: 1px solid #d2d2d2;
}
